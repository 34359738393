const makeACall = async ({
    url,
    body = false,
    endpoint = "",
    method = "POST",
  }) => {
    const headers = new Headers({
      "Content-Type": "application/x-www-form-urlencoded",
    });
    const options = {
      method,
      headers,
      mode: "cors",
      cache: "default",
    };
    if (body) {
      Object.assign(options, { body });
    }
    const request = new Request(url + endpoint, options);
    const data = await fetch(request);
    const json = await data.json();
    if (json) return json;
  };

  export { makeACall }