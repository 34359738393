import styles from './BusinessCard.module.css';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';


export default function BusinessCard() {
    return (
        <div className={styles.container}>
            <div className={styles.businessCardContainer}>
                <img src="https://lh3.googleusercontent.com/pw/AL9nZEVmMVKRXK9ZrT8bqdytSuBPqH0xzMtip8_al0MSZ_ayBVw7aguvLyauOFNKslZyq_kQS4oV76v8KVNK0kmXph32EJ3TkuMXhKXRV6EkrmsTwhEH34E3xYUeda5RIDKUqX0k238fvY73TJUIGIzlEjQ9Cw=s640-no?authuser=0"
                    alt="novosolov_photo" className={styles.businessCardImg} />
                <div className={styles.personalData}>
                    <div className={styles.infoContainer}>
                        <span className={styles.contactName}>Iurii Novosolov</span>
                        <span className={styles.contactPosition}>CЕО</span>

                        <a className={styles.website} href="http://adultmentalarithmetic.com">http://adultmentalarithmetic.com</a>
                        <a className={styles.feedback} href="https://calendly.com/adultmentalarithmetic/15min">Schedule a call with me</a>
                       
                        <div className={styles.socialNetworksContainer}>
                            <a className={styles.link} href="https://wa.me/+380676336697">
                                <WhatsAppIcon style={{height: 60, width: 60, color: '#25D366'}} />
                            </a>
                         
                            <a className={styles.link} href="https://www.linkedin.com/in/iurii-novosolov/">
                                <LinkedInIcon style={{height: 60, width: 60, color: '#0e76a8'}} />
                            </a>

                            <a className={styles.link} href="https://twitter.com/INovosolov">
                                <TwitterIcon style={{height: 60, width: 60, color: '#00acee'}} />
                            </a>
                        
                            <a className={styles.link} href="https://www.youtube.com/watch?v=pj_p4_SJt-8">
                                <YouTubeIcon style={{height: 65, width: 86, color: '#c4302b'}} />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}











