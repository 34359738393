import RouterComponent from "../../router";
import React from "react";

function App() {
  return (
    <>
      <RouterComponent />
    </>
  );
}

export default App;
