/* eslint-disable */

import { ApiConnector, GET, POST } from './request.service';

export default (() => {
  const API = ApiConnector('/callback');
  return {
    Send: async (data) => {
      const res = await API(POST, '/org').call(data);
      return res;
    },
  };
})();
