import { Link, useParams, Navigate } from "react-router-dom";
import { videos } from "../../../constants";
import { Box } from '@material-ui/core';
import styles from '../Video/VideoPage.module.css';
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Button, Stack, Container } from "@mui/material";


export default function VideoPage() {
  const { videoId } = useParams();
  const video = videos.find(video => video.id === `${videoId}`);

  if (!video) return <Navigate to={"/gallery"} />

  return (
    <div>
      <Button className={styles.buttonGoBack}
        component={Link}
        to={"/gallery"}
        style={{ color: '#fff', padding: '15px' }}
        startIcon={<ArrowBackIosIcon sx={{ fontSize: 14, color: '#fff' }} />}
      >
        Go back
      </Button>
      <h1 className={styles.title}>{video.title}</h1>
               
      <Box
        className={styles.container}
        key={video}
        item   
      >
        <div className={styles.iframeContainer}>
          <video width="100%" height="100%" src={video.url} controls autobuffer></video>
        </div>
        <p className={styles.cardTitle}>{video.title}</p>
      </Box>
    </div>
  );
}