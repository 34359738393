import { useMemo } from "react";
import "./Home.css";
import { useState } from "react";
import Loader from "../../../componets/Loader";
import { FullPageComponent } from "../../../componets/FullPage";
import { CookieAgreement } from "../../../componets";

import { images } from "../../../constants";
// import Menu from "../../../componets/Menu";


import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import FavoriteIcon from '@material-ui/icons/Favorite';
import NavigationIcon from '@material-ui/icons/Navigation';
import { Link } from "react-router-dom";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     '& > *': {
//       margin: theme.spacing(1),
//     },
//   },
//   extendedIcon: {
//     marginRight: theme.spacing(1),
//   },
// }));

export default function HomePage() {
  const [loading, setLoading] = useState(0);
  const loaded = useMemo(() => {
    // return loading >= 14;
    return loading === images.length - 1;
  }, [loading]);
  return (
    <>
      <div className={`loader${!loaded ? " loading" : ""}`}>
        <Loader />
      </div>
      {/* <Menu /> */}
      <Fab
        component={Link}
        to={"/gallery"}      
        variant="extended"
        style={{ position: 'fixed', right: 30, top: 30, zIndex: 10 }}>
        {/* <NavigationIcon className={classes.extendedIcon} /> */}
        Gallery
      </Fab>
      <FullPageComponent setLoading={setLoading} />
      <CookieAgreement />
    </>
  );
}
