import { FormComponent } from "../Form";
import ReactFullpage from "@fullpage/react-fullpage";
import { DialogTitle, Box, Paper } from "@mui/material";

import { images } from "../../constants";
import React from "react";
import Zoom from 'react-reveal/Zoom';
import useMediaQuery from "../../hooks/useMediaQuery";


export default function FullPageComponent({ setLoading }) {

  const currentYear = new Date().getFullYear();
  const isDesktop = useMediaQuery('(min-width: 700px)');

  return (
    <Box>
      <ReactFullpage
        licenseKey={"H3ZK9-5O3R7-A7J98-04OO8-LZNSO"}
        render={() => {
          return (
            <>
              {/* {images.slice(0, 12).map((item, index) => { */}
              {images.map((item, index) => {
                if (item.hidden) return null;
                return (
                  <div
                    key={index}
                    className="section"
                    style={{ height: "100vh" }}
                  >
                    <div
                      className="background image"
                      style={{ backgroundImage: isDesktop ? `url(${item.image})` : `url(${item.imageMob})` }}
                    >
                      <Zoom delay={3000} timeout={7000}>
                        <div className="textConteiner">
                          {item.text.length
                            ? item.text.map((el) => (
                              <h1 className="text">
                                {/* <Zoom cascade delay={5000} timeout={3000}> */}
                                {el}
                                {/* </Zoom> */}
                              </h1>
                            ))
                            : null}
                        </div>
                      </Zoom>
                      </div> 
                    <img
                      src={item.image}
                      // src={isDesktop ? item.image : item.imageMob}
                      style={{ display: "none" }}
                      onLoad={() => setLoading((item) => item + 1)}
                    />
                  </div>
                );
              })}  
              <div className="section" style={{ height: "100vh" }}>
                <div
                  className="background image"
                  style={{ backgroundImage: isDesktop ?  `url(${images[12].image})` : `url(${images[12].imageMob})` }}
                >
                </div>  
                  <div className="copyright">
                    ©Copyright 2013-{currentYear} by Iurii Novosolov. All rights
                    reserved.
                    <br />
                    <a target="_blank" href="https://soroboom.com/privacy-policy">
                      Privacy policy
                    </a>{" "}
                    Any copying of the resource materials without the written
                    consent of the owner is prohibited.
                </div>
              </div>

              <div className="section" style={{ height: "100vh" }}>    
                <div
                  className="background image"
                  style={{ backgroundImage: isDesktop ?  `url(${images[13].image})` : `url(${images[13].imageMob})` }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      "& > :not(style)": {
                        m: 1,
                        maxHeight: "645px",
                        overflow: "auto",
                      },
                    }}
                  >
                    <Paper elevation={12}>
                      <Box
                        sx={{
                          "& > :not(style)": {
                            p: 2,
                          },
                        }}
                      >
                        <DialogTitle>Let's introduce ourselves!</DialogTitle>
                        <FormComponent />
                      </Box>
                    </Paper>
                  </Box>
                </div>
                <img
                  src={images[13].image}
                  // src={isDesktop ? images[6].image : images[6].imageMob}
                  style={{ display: "none" }}
                  onLoad={() => setLoading((item) => item + 1)}
                />
              </div>
            </>
          );
        }}
      ></ReactFullpage>
    </Box>
  );
}
