import React from "react";
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { HomePage, BlogPage, BusinessCardPage, GalleryPage, VideoPage } from "./Pages";


export default function RouterComponent() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/businessCard" element={<BusinessCardPage />} />
        <Route path="/gallery" element={<GalleryPage />} />
        <Route path="/gallery/:videoId" element={<VideoPage />} />
      </Routes>
    </Router>
  );
}
