// import { Typography } from "@mui/material";
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
// import MediaCard from '../MediaCard'
import styles from './GalleryComponent.module.css';
import { videos } from "../../constants";
import { Link, useNavigate, useParams } from "react-router-dom";


// import InputBase from '@material-ui/core/InputBase';
// import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Button, Stack, Container } from "@mui/material";
// import Stack from '@mui/material/Stack';
import { Box, InputBase, IconButton } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  root: {
    // padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 350,
    marginLeft: 'auto',
    marginRight: 'auto',
    // marginTop: '25px',
    // marginBottom: '25px',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
}));


export default function GalleryComponent() {
  const [spacing, setSpacing] = useState(3);
  const [inputValue, setInputValue] = useState('');
  const [searchVideo, setSearchVideo] = useState(false);
  const classes = useStyles();
  const navigate = useNavigate();

  

  
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(inputValue)
    // const searchVideo = true
    setSearchVideo(!searchVideo)
    // reset()
  };
  
  const handleChange = (e) => {
    const inputValue = e.target.value
    setInputValue(inputValue)
  };
  
  const reset = () => {
    const inputValue = ""
    setInputValue(inputValue)
  };

  return (
    <Box style={{ marginTop: '0', marginBottom: '40px' }}>
      <Button className={styles.buttonGoBack}
        component={Link}
        to={"/"}
        style={{ color: '#fff', padding: '15px' }}
        startIcon={<ArrowBackIosIcon sx={{ fontSize: 14, color: '#fff' }} />}
      >
        Go back
      </Button>
      <Paper component="form" className={classes.root} style={{ marginTop: '0', marginBottom: '35px' }} onSubmit={handleSubmit}>
        <InputBase
          className={classes.input}
          placeholder="Search video"
          name="search"
          value={inputValue}
          onChange={handleChange}
        />
        <IconButton type="submit" className={classes.iconButton} >
          <SearchIcon />
        </IconButton>
      </Paper>

      {searchVideo ? (
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={5}
        >
          {videos.filter(video => video.title.toLowerCase().includes(`${inputValue.toLowerCase()}`))
            .map((video) => (    
              <Box
                className={styles.cardContainer}
                key={video}
                item
                component={Link}
                to={`/gallery/${video.id}`}
              >
                <div className={styles.iframeContainer}>
                  <video width="100%" height="100%" src={video.url} controls autobuffer></video>
                </div>
                <p className={styles.cardTitle}>{video.title}</p>
              </Box>    
            ))}
        </Stack>
        ) : (
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={5}
        >
          {videos.map((video) => (
            <Box
              className={styles.cardContainer}
              key={video}
              item
              component={Link}
              to={`/gallery/${video.id}`}
            >
              <div className={styles.iframeContainer}>
                <video width="100%" height="100%" src={video.url} controls autobuffer></video>
              </div>
              <p className={styles.cardTitle} >{video.title}</p>
            </Box>
          ))}
        </Stack>
      )}
    </Box>
  );
}




















// import { Typography } from "@mui/material";
// import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
// import Grid from '@material-ui/core/Grid';
// import Paper from '@material-ui/core/Paper';
// // import MediaCard from '../MediaCard'
// import styles from './GalleryComponent.module.css';
// import { videos } from "../../constants";
// import { Link, useNavigate, useParams } from "react-router-dom";



// // import { makeStyles } from '@material-ui/core/styles';
// // import Paper from '@material-ui/core/Paper';
// import InputBase from '@material-ui/core/InputBase';
// import IconButton from '@material-ui/core/IconButton';
// import SearchIcon from '@material-ui/icons/Search';
// import { useState } from "react";
// import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
// import { Button, Typography, Container } from "@mui/material";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     padding: '2px 4px',
//     display: 'flex',
//     alignItems: 'center',
//     width: 350,
//     marginLeft: 'auto',
//     marginRight: 'auto',
//     marginTop: '25px',
//     marginBottom: '25px',
//   },
//   input: {
//     marginLeft: theme.spacing(1),
//     flex: 1,
//   },
//   iconButton: {
//     padding: 10,
//   },
// }));


// export default function GalleryComponent() {
//   const [spacing, setSpacing] = useState(3);
//   const [inputValue, setInputValue] = useState('');
//   const [searchVideo, setSearchVideo] = useState(false);
//   const classes = useStyles();
//   const navigate = useNavigate();

  
//   const handleSubmit = (e) => {
//     e.preventDefault();
//     console.log(inputValue)
//     // const searchVideo = true
//     setSearchVideo(!searchVideo)
//     // reset()
//   }
  
//     const handleChange = (e) => {
    
//       // console.log(e.target.value)
//       const inputValue = e.target.value
//       setInputValue(inputValue)

//   }
  
//   const reset = () => {
//           const inputValue = ""
//       setInputValue(inputValue)
//   }
// // console.log(inputValue)
//   return (
//     <>
//       <div style={{ display: 'flex' }}>
//                      <Button
//           component={Link}
//         to={"/"}
//         style={{ color: '#fff', marginLeft: '5px'}}
//           startIcon={<ArrowBackIosIcon sx={{ fontSize: 14, color: '#fff' }} />}
//         >
//           Go back
//         </Button>
//       <Paper component="form" className={classes.root} onSubmit={handleSubmit}>
//         <InputBase
//           className={classes.input}
//           placeholder="Search video"
//           name="search"
//           value={inputValue}
//           onChange={handleChange}
//         // inputProps={{ 'aria-label': 'search video' }}
//         />
//         <IconButton type="submit" className={classes.iconButton} aria-label="search" >
//           <SearchIcon />
//         </IconButton>
//       </Paper>

//         </div>
//       {/* <Grid container className={classes.root} spacing={2} style={{ paddingTop: "30px" }}> */}

//       {searchVideo ? (
//         <Grid container spacing={2}>
//           <Grid item xs={12}>
//             <Grid container justifyContent="center" spacing={spacing}>
//               {videos.filter(video => video.title.toLowerCase().includes(`${inputValue.toLowerCase()}`))
//                 .map((video) => (
//                   <Grid key={video} item>
                
//                     <div className={styles.cardContainer}>
//                       <div>
//                         <iframe
//                           src={video.url}
//                           width="340"
//                           height="200"
//                           allow="autoplay;"
//                         ></iframe>
//                       </div>
//                       <p className={styles.cardTitle}>{video.title}</p>
//                     </div>
//                   </Grid>
//                 ))}
//             </Grid>
//           </Grid>
//         </Grid>
//       ) : (
//         <Grid container spacing={2}>
//           <Grid item xs={12}>
//             <Grid container justifyContent="center" spacing={spacing}>
//               {videos.map((video) => (
//                 <Grid key={video} item>
//                   {/* <MediaCard /> */}
//                   <div className={styles.cardContainer}>
//                     <div>
//                       <iframe
//                         src={video.url}
//                         width="340"
//                         height="200"
//                         // frameborder="0"
//                         allow="autoplay; fullscreen"
//                       // allowfullscreen
//                       ></iframe>
//                     </div>
//                     <p className={styles.cardTitle}>{video.title}</p>
//                   </div>
//                   {/* <Paper className={classes.paper} /> */}
//                 </Grid>
//               ))}
//             </Grid>
//           </Grid>
//         </Grid>
//       )}
//     </>
//   );
// }