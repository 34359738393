/* eslint-disable */

import API_ROOT from "../constants/urlAPI";
export const GET = (...args) => makeRequest("GET", ...args);
export const POST = (...args) => makeRequest("POST", ...args);
export const PUT = (...args) => makeRequest("PUT", ...args);
export const DELETE = (...args) => makeRequest("DELETE", ...args);

const formData = (object) =>
  Object.keys(object).reduce((form, key) => {
    form.append(key, object[key]);
    return form;
  }, new FormData());

async function makeRequest(
  method,
  endpoint,
  rawData = false,
  multipart = false
) {
  const headers = new Headers({
    "Content-Type": "application/json",
  });

  const options = {
    method,
    headers,
    mode: "cors",
    cache: "default",
  };
  const body =
    !!rawData && ((multipart && formData(rawData)) || JSON.stringify(rawData));

  if (body) {
    Object.assign(options, { body });
  }

  const request = new Request(API_ROOT + endpoint, options);

  try {
    const data = await fetch(request);
    const json = await data.json();
    if (json.status) {
      return json.payload;
    }
  } catch ({ message, name, number, url }) {}
}

const makeToQuery = (query, noGet = false) => {
  if (!query) return "";
  const str = [];
  for (const p of Object.keys(query))
    str.push(`${encodeURIComponent(p)}=${encodeURIComponent(query[p])}`);
  if (noGet) return str.join("&");
  return `?${str.join("&")}`;
};

export const ApiConnector = (prefix = "") => {
  return (callback, endpoint, multipart = false, query = false) => ({
    async call(body = false, skipGlobalErrorStack = false) {
      try {
        return await callback(
          ...[prefix + endpoint + makeToQuery(query), body, multipart]
        );
      } catch (message) {
        // store.dispatch({
        //     type: Types.API_ERROR,
        //     payload: {
        //         id: uuid(),
        //         time: Date.now(),
        //         endpoint: prefix + endpoint,
        //         body,
        //         message
        //     }
        // });
        //
        // if (skipGlobalErrorStack) throw new Error(message);
        // else {
        //     enqueueSnackbar({
        //         message,
        //         options: {
        //             variant: "error"
        //         }
        //     })(store.dispatch);
        // }
      }
    },
  });
};
