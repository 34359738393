import React, { useEffect } from "react";

import InputMask from "react-input-mask";
import * as Yup from "yup";
import { Stack, TextField, Typography } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import { useFormik, Form, FormikProvider } from "formik";
import CallbackService from "../../services/callback.service";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import { makeACall } from "../../actions";
import RefreshIcon from "@mui/icons-material/Refresh";

const captchaUrl = "https://front-api.soroban.ua/api/captcha/?token=!Aa12345";

const captchaVerify =
  "https://front-api.soroban.ua/api/captcha/verify/?token=!Aa12345";

export default function FormComponent() {
  const [success, setSuccess] = useState(false);
  const [errorCaptcha, setErrorCaptcha] = useState(false);
  const LoginSchema = Yup.object().shape({
    email_organization: Yup.string()
      .email("Wrong email format")
      .required("Required"),
    phone: Yup.string().required("Required"),
    name: Yup.string().required("Required"),
    captcha: Yup.string().required("Enter Captcha"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      email_organization: "",
      address: "",
      organization: "",
      web_page: "",
    },
    validationSchema: LoginSchema,
    onSubmit: async () => {
      setErrorCaptcha(false);

      const captchaRes = await makeACall({
        url: captchaVerify,
        method: "POST",
        body: JSON.stringify({ text: values.captcha, key: captcha.key }),
      });
      if (captchaRes) {
        setErrorCaptcha(!captchaRes.correct);
        setTimeout(() => {
          setErrorCaptcha(false);
        }, 5000);
      }
      if (captchaRes && captchaRes.correct) {
        console.log(values)
        const res = await CallbackService.Send(values);
        if (res && typeof res === "object" && Object.keys(res).length === 0) {
          setSuccess(true);
        }
      }
    },
  });

  const [captcha, setCaptcha] = useState({ image: "", key: "" });

  const fetchData = async () => {
    const res = await makeACall({ url: captchaUrl, method: "GET" });
    if (res && res.image && res.key) {
      setCaptcha({ ...res });
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Collapse in={!success}>
          <Stack direction="column" spacing={3}>
            <TextField
              name="name"
              required
              placeholder="Name"
              fullWidth
              {...getFieldProps("name")}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
              disabled={isSubmitting}
              variant="standard"
            />
            <InputMask
              mask="+(1) 999 999 9999"
              {...getFieldProps("phone")}
              disabled={isSubmitting}
            >
              {(props) => (
                <TextField
                  name="phone"
                  required
                  placeholder="Phone"
                  fullWidth
                  {...getFieldProps("phone")}
                  error={Boolean(touched.phone && errors.phone)}
                  helperText={touched.phone && errors.phone}
                  variant="standard"
                  {...props}
                />
              )}
            </InputMask>
            <TextField
              name="email_organization"
              required
              placeholder="Email"
              fullWidth
              {...getFieldProps("email_organization")}
              error={Boolean(
                touched.email_organization && errors.email_organization
              )}
              helperText={
                touched.email_organization && errors.email_organization
              }
              variant="standard"
              disabled={isSubmitting}
            />
            <TextField
              name="address"
              required
              placeholder="Address"
              fullWidth
              {...getFieldProps("address")}
              error={Boolean(touched.address && errors.address)}
              helperText={touched.address && errors.address}
              variant="standard"
              disabled={isSubmitting}
            />
            <TextField
              name="organization"
              required
              placeholder="Organization"
              fullWidth
              {...getFieldProps("organization")}
              error={Boolean(touched.organization && errors.organization)}
              helperText={touched.organization && errors.organization}
              variant="standard"
              disabled={isSubmitting}
            />
            <TextField
              name="web_page"
              required
              placeholder="Web Page"
              fullWidth
              {...getFieldProps("web_page")}
              error={Boolean(touched.web_page && errors.web_page)}
              helperText={touched.web_page && errors.web_page}
              variant="standard"
              disabled={isSubmitting}
            />

            <div className="captcha txt-center">
              <div>
                <img
                  alt="Error with a captcha"
                  src={`data:image/svg+xml;base64,${captcha.image}`}
                />
                <RefreshIcon onClick={fetchData} />
              </div>
              <TextField
                name="captcha"
                required
                placeholder="Enter answer from the picture "
                fullWidth
                {...getFieldProps("captcha")}
                variant="standard"
                style={{ textAlign: "center" }}
                disabled={isSubmitting}
              />
              <Collapse in={errorCaptcha}>
                <Typography style={{ color: "red" }} variant="caption">
                  Captcha not valid.
                </Typography>
              </Collapse>
            </div>

            <LoadingButton type="submit" loading={isSubmitting}>
              Submit
            </LoadingButton>
          </Stack>
        </Collapse>
        <Collapse in={success}>
          <Typography variant="body1" gutterBottom>
            The email was successfully sent! You will be contacted later.
          </Typography>
        </Collapse>
      </Form>
    </FormikProvider>
  );
}
