const videos = [
    {
        title: "Brain training in golden age",
        id: 'brain_training_in_golden_age',
        url: "https://testsoroban.s3.eu-north-1.amazonaws.com/Soroban_55-%D0%90%D0%9D%D0%93%D0%9B-%D0%B5%D1%89%D0%B5+%D1%82%D0%B8%D1%88%D0%B5+%D1%80%D1%83%D1%81%D0%BA%D0%B8%D0%B9+%D0%B3%D0%BE%D0%BB%D0%BE%D1%81.mp4",
        
    },
    {
        title: "What results do children achieve - Polina",
        id: 'what_results_do_children_achieve-Polina',
        url: "https://testsoroban.s3.eu-north-1.amazonaws.com/POLINA.mp4",
    },
    {
        title: "What results do children achieve - Sava",
        id: "what_results_do_children_achieve-Sava",
        url: "https://testsoroban.s3.eu-north-1.amazonaws.com/%D0%A1%D0%90%D0%92%D0%90-%D0%BA%D0%BE%D1%80%D0%BE%D1%82%D0%BA%D0%B8%D0%B9+%D0%B4%D0%BB%D1%8F+%D0%A2%D0%B0%D0%BD%D0%B8.mp4",
    },
    {
        title: "TV show about training the brain of the elderly",
        id: "TV_show_about_training_the_brain_of_the_elderly",
        url: "https://testsoroban.s3.eu-north-1.amazonaws.com/%D0%9C%D0%B0%D0%BB%D1%8B%D1%88%D0%B5%D0%B2%D0%B0-%D1%87%D0%B8%D1%81%D1%82%D0%BE%D0%B2%D0%B8%D0%BA.mp4",
    },
];


export { videos };
    

    
    
    
// const videos = [
//     {
//         title: "Brain training in golden age",
//         url: "https://player.vimeo.com/video/772360961?h=88568424ea",
//     },
//     {
//         title: "Polina",
//         url: "https://player.vimeo.com/video/772348892?h=0653ad0789",
//     },
//     {
//         title: "Brain training in golden age",
//         url: "https://player.vimeo.com/video/772360961?h=88568424ea",
//     },
//     {
//         title: "Polina",
//         url: "https://player.vimeo.com/video/772348892?h=0653ad0789",
//     },
//     {
//         title: "Brain training in golden age",
//         url: "https://player.vimeo.com/video/772360961?h=88568424ea",
//     },
//     {
//         title: "Polina",
//         url: "https://player.vimeo.com/video/772348892?h=0653ad0789",
//     },
// ];