import {
  one,
  one_mob,
  two,
  two_mob,
  three,
  three_mob,
  four,
  four_mob,
  five,
  five_mob,
  six,
  six_mob,
  seven,
  seven_mob,
  eight,
  eight_mob,
  nine,
  nine_mob,
  ten,
  ten_mob,
  eleven,
  eleven_mob,
  twelve,
  twelve_mob,
  thirteen,
  thirteen_mob,
  fourteen,
  fourteen_mob,
} from "../assets/img";


const images = [
  {
    image: one,
    imageMob: one_mob,
    text: [
      'A whirlwind of a life. The person is in high demand. Everyone needs ingenuity, intelligence, the ability to learn quickly, and operational thinking. Of course, he himself, without realizing it, is happy in this cycle, confidently carrying out their duties and succeeding at them.',
    ],
  },
  {
    image: two,
    imageMob: two_mob,    
    text: [
      "Aging cannot be avoided. Like the body, the brain, too, is subject to aging. Its processing speed deteriorates, the once sharp mind becomes dull, and the ability to multitask depletes. These cognitive difficulties also compromise an individual’s opportunity and potential on the market. Memory problems can be frustrating and even develop into more serious problems, such as Alzheimer's and dementia.",
    ],
  },
  {
    image: three,
    imageMob: three_mob,
    text: [
      "What is the way out: to be treated, to fight, or to reconcile? Of course the answer is to fight, but how? This is a question that worries millions of people who are over the age of 65.",
    ],
  },
  {
    image: four,
    imageMob: four_mob,
    text: [
      "You've probably seen kids who can count faster than a calculator and boast phenomenal multitasking abilities. They are not natural geniuses. These kids train their brains in Soroban schools in different countries cities in the world. If you did not see them, then you were caught up in the whirlwind of life.",
    ],
  },
  {
    image: five,
    imageMob: five_mob,
    text: [
      "It is worth mentioning that in 2013…",
      "2015…",
      "2018…18",
      "2019…",
      "2020 edition Esoroban is a patented device that uses Internet of Things technology to connect to a child's school and track their progress.",
    ],
  },
  {
    image: six,
    imageMob: six_mob,
    text: [
      "2020 was a special year. Something unique happened as Soroban. Our employees were successful in training the brain of elderly people using electronic bills. This is a new learning method that seniors can use to improve their mental numeracy skills.",
    ],
  },
  {
    image: seven,
    imageMob: seven_mob,
    text: [
      "Pre- and post-workout tests on the abacus show incredible improvements in multitasking, communication, attention, memory, speed, and composure. The test results showed that the students significantly improved their mental abilities after the training.",
    ],
  },
  {
    image: eight,
    imageMob: eight_mob,
    text: [
      "We have found that this training method improves mental numeracy in older people and helps them stay sharp and mentally active. We are the first company to offer this type of training, and we are confident that it will help seniors lead healthier and more active lives.",
    ],
  },
  {
    image: nine,
    imageMob: nine_mob,
    text: [
      "You know people aged 65+. You’ve also witnesses their decline and the need to restore their cognitive functions. Work with us to give them this invaluable opportunity! We have opened schools in partnership in different cities and countries worldwide. More than 20,000 students from 7 to 16 years old already surpass their peers in their abilities.",
    ],
  },
  {
    image: ten,
    imageMob: ten_mob,
    text: [
      "In cooperation with you, we will be able to apply all our proven methods. Your help in spreading the word about this technology and our hard work and intellectual resources will help seniors stay in shape.",
    ],
  },
  {
    image: eleven,
    imageMob: eleven_mob,
    text: [
      "Aging should not be something to fear. Every age is amazing, and our goal is to give you the opportunity to fully enjoy life form start to finish. By challenging their minds, we can help older adults live happier and healthier lives. So let's get started today!",
    ],
  },
  {
    image: twelve,
    imageMob: twelve_mob,
    text: [
      "With pride and a sense of accomplishment, we are ready to share our knowledge and technology. Our methodologists, programmers, and psychologists will train others who will carry out our noble cause to people who need it.",
    ],
  },
  {
    image: thirteen,
    imageMob: thirteen_mob,
    text: [],
    hidden: true,
  },
  {
    image: fourteen,
    imageMob: fourteen_mob,
    text: [""],
    hidden: true,
  },
];


export { images };
