import * as React from "react";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import "./Cookie.css";
export default function CookieAgreement() {
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <Button size="small" onClick={handleClose}>
        I understand
      </Button>
    </React.Fragment>
  );

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      message={
        <div className="CookieAgreement">
          This website uses cookies to enhance the user experience.
          <br />
          By interacting with the site, you agree to{" "}
          <a target="_blank" href="https://soroboom.com/privacy-policy">
            privacy policy
          </a>
          .
        </div>
      }
      action={action}
    />
  );
}
